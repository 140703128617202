import DataLayerManager from "../trackers/dataLayer/dataLayerManager";

export const filterAvailableOptions = ['thematic', 'featureddates', 'allowpets', 'mealplan', 'accommodationcategory', 'rating', 'price', 'freecancellation', 'location', 'whentravel'];

export default class AvailabilityFilter {
    constructor() {
        this.dataLayerManager = new DataLayerManager();
        this.extraGroupList = [];
        this.groupList = [];
        this.tagsDisplayedLimit = 8;
        this.filtersApplied = {
            'thematic': [],
            'featureddates': [],
            'allowpets': [],
            'mealplan': [],
            'accommodationcategory': [],
            'rating': [],
            'price': [],
            'freecancellation': [],
            'location': [],
            'whentravel': []
        };
        this.filterAvailableOptions = filterAvailableOptions;
        this.counterFilterAvailableGroups = {
            'thematic': [],
            'featureddates': [],
            'allowpets': [],
            'mealplan': [],
            'accommodationcategory': [],
            'rating': [],
            'price': [],
            'freecancellation': [],
            'location': [],
            'whentravel': []
        };
        this.tagList = {
            'thematic': [],
            'featureddates': [],
            'allowpets': [],
            'mealplan': [],
            'accommodationcategory': [],
            'rating': [],
            'price': [],
            'freecancellation': [],
            'location': [],
            'whentravel': []
        };
        this.filtersWithORCondition = ['accommodationcategory', 'mealplan', 'featureddates', 'rating', 'price', 'location', 'whentravel'];
        this.filtersContainer = $('[data-container="filters"]');
        this.initialize();
    }

    initialize() {
        this.calculateCollapseHeight();
        this.initializeFilters();
        this.setUserFilters();
        if (this.checkAnyFiltersApplied(true)) {
            this.doFilter();
        }
        this.eventDelegation();
        this.initializeWithGetParams();
    };

    eventDelegation() {
        this.filtersContainer.on("click", "[data-action='filter']", (event) => {
            let currentElement = $(event.currentTarget);

            if (currentElement.hasClass('label-disabled')) {
                return false;
            }

            if (currentElement.data('field') === 'groups-counter') {
                currentElement = currentElement.prev().find("[data-filter-type]");
                currentElement.prop('checked', !currentElement.is(':checked'));
            }
            this.toggleClassFilterCheckbox(currentElement);

            this.refreshGetParams(currentElement);

            const categoryElement = currentElement.closest('[data-container-filter-type]').data('container-filter-type');
            this.triggerFilterClick(currentElement);
            this.doFilter(categoryElement);
        });

        const body = $('body');
        body.on("click", "[data-action='reset-filters'], [data-action='restore-filters']", () => {
            this.restoreFilters();
        });

        body.on("click", "[data-action='reset-filter']", (event) => {
            let currentFilter = $(event.currentTarget).data('filter-applied-id').split('-');
            this.restoreFilter(currentFilter[0], currentFilter[1]);
        });

        $('[data-open="filters"]').on('click', () => {
            this.filtersContainer.addClass('show');
            body.addClass('filters-opened');
        });

        $('[data-close="filters"]').on('click', () => {
            this.filtersContainer.removeClass('show');
            body.removeClass('filters-opened');
        });
    };

    triggerFilterClick(elementContainer) {
        let data = {
            event: "filter_interaction",
            event_category: "filter",
            event_detail_1: elementContainer.data('filter-type'),
            event_detail_2: elementContainer.data('filter-name'),
            event_detail_3: elementContainer.is(':checked') ? 'check' : 'uncheck'
        };

        this.dataLayerManager.pushData(JSON.stringify(data));
    }

    doFilter(filterCategory) {
        this.initializeProcess();
        this.initializeFilters();
        this.setUserFilters();
        this.applyFilters(filterCategory);
        this.finishProcess();
    };

    initializeProcess() {
        $('#filter_overlay').fadeIn(100);
    };

    finishProcess() {
        $('#filter_overlay').fadeOut(1000);
    };

    applyFilters(filterCategory) {
        this.setVisibilityAvailableGroups(filterCategory);
        this.setVisibilityAvailableChollos(filterCategory);
        if (!this.checkAnyFiltersApplied()) {
            myApp.book.availabilityManager.setVisibleGroups();
        }

        this.printUpdatedCounters();
        this.printGroups();
        this.setSummaryFiltersApplied();
        this.updateExtra();
        this.manageCollapseFilters();

        this.manageComingSoonMessageVisibility();

        myApp.book.availabilityManager.reorderBanners();

        let divToScroll = '[data-container="filter-title"]';
        if (!$(divToScroll).is(':visible')) {
            divToScroll = '[data-container="groups"]';
        }
        myApp.scrollManager.scrollToElement(divToScroll);

        $('#filter_overlay').fadeOut(100);
    };

    initializeFilters() {
        myApp.book.availabilityManager.setVisibleGroups();
        this.filtersApplied = {
            'thematic': [],
            'featureddates': [],
            'allowpets': [],
            'mealplan': [],
            'accommodationcategory': [],
            'rating': [],
            'price': [],
            'freecancellation': [],
            'location': [],
            'whentravel': []
        };
        this.counterFilterAvailableGroups = {
            'thematic': [],
            'featureddates': [],
            'allowpets': [],
            'mealplan': [],
            'accommodationcategory': [],
            'rating': [],
            'price': [],
            'freecancellation': [],
            'location': [],
            'whentravel': []
        };
    };

    manageCollapseFilters() {
        this.calculateCollapseHeight();
        $.each(this.filterAvailableOptions, (i, category) => {
            let seeMoreContainer = $("[data-container-filter-type='" + category + "']").find("[data-toggle=collapse]");
            if (seeMoreContainer.length > 0) {
                let collapsed = true;
                $.each(seeMoreContainer, function (i, seeMore) {
                    if (!$(seeMore).hasClass('collapsed')) {
                        collapsed = false;
                    }
                });

                const infoGroupCollapse = $(".info-group-collapse[data-id='" + category + "']");
                if (!collapsed) {
                    if (this.counterFiltersOnFilterCategory(category)) {
                        // Filter collapsed and more than max filters.
                        infoGroupCollapse.collapse('show');
                        infoGroupCollapse.next().show();
                    } else {
                        // Filter collapsed and less than max filters.
                        infoGroupCollapse.collapse('show');
                        infoGroupCollapse.next().hide();
                    }
                } else {
                    if (this.counterFiltersOnFilterCategory(category)) {
                        //Filter no collapsed and more than max filters.
                        const linkCollapse = $("a[data-toggle='collapse'][href='#" + category + "']");
                        myApp.textTransformer.initializeTextElement(linkCollapse);
                        infoGroupCollapse.collapse('hide');
                        infoGroupCollapse.next().show();
                    } else {
                        //Filter no collapsed and less than max filters.
                        infoGroupCollapse.collapse('show');
                        infoGroupCollapse.next().hide();
                        seeMoreContainer.addClass('collapsed');
                    }
                }
            }
        });
    };

    setTagCounterAmount(group, orFiltered, categoryType) {
        let filtered = false;
        let counterFilter = true;
        if (orFiltered) {
            $.each(this.filtersApplied, (appliedCategory, ids) => {
                if (ids.length === 0 || appliedCategory === categoryType) {
                    return true;
                }
                $.each(group.categories[appliedCategory], (category, id) => {
                    if (ids.indexOf(id) >= 0) {
                        filtered = true;
                    }
                });
                if (!filtered) {
                    counterFilter = false;
                    return false;
                } else {
                    filtered = false;
                }
            });
        }

        if (counterFilter) {
            $.each(group.categories, (category, ids) => {
                if (orFiltered === false || (categoryType === category && this.filtersWithORCondition.indexOf(category) >= 0)) {
                    $.each(ids, (i, id) => {
                        if (typeof this.tagList[category][id] !== 'undefined') {
                            this.tagList[category][id]['amount']++;
                        }
                    });
                }
            });
        }
    };

    printUpdatedCounters() {
        $.each(this.tagList, (name, category) => {
            let tagsDisplayed = 0;
            for (const idTag in category) {
                const filterCounterItem = this.filtersContainer.find("[data-filter-type='" + name + "'][data-id='" + idTag + "']").parent().next("[data-field='groups-counter']");
                const counterAmount = category[idTag]['amount'];
                filterCounterItem.text(counterAmount);
                if (counterAmount > 0) {
                    tagsDisplayed++;
                }
            }
            if (tagsDisplayed === 0) {
                $("[data-container-filter-type='" + name + "']").hide();
            }else{
                $("[data-container-filter-type='" + name + "']").show();
            }
        });

        this.changeDomCounterAvailableGroups();
    };

    toggleClassFilterCheckbox(checkbox) {
        var checkboxContainer = checkbox.closest(".checkbox");
        $(checkboxContainer).find("[data-field='groups-counter']").removeClass("label-primary").addClass("label-secondary-gray");
        if (checkbox.is(":checked")) {
            $(checkboxContainer).find("[data-field='groups-counter']").removeClass("label-secondary-gray").addClass("label-primary");
        }
    };

    setUserFilters() {
        $.each($("[data-filter-type]"), (i, checkbox) => {
            const filterType = $(checkbox).data('filter-type');
            const filterId = $(checkbox).data('id');
            this.tagList[filterType][filterId] = {'amount': 0};
            if ($(checkbox).is(':checked') && this.filtersApplied[filterType].indexOf(filterId) === -1) {
                this.filtersApplied[filterType].push(filterId);
            }
        });
    };

    manageComingSoonMessageVisibility() {
        const comingSoonModule = $("[data-entity='comingSoonModule']");

        let isVisibleOnSaleSection = false;
        const isVisibleFeaturedSection = $("[data-category='featured']").is(':visible');
        const isVisibleUserExclusiveSection = $("[data-category='user_exclusive']").is(':visible');
        const isVisibleComingSoonSection = $("[data-category='coming_soon']").is(':visible');

        $.each($("[data-category='on_sale']"), (i, category) => {
            if ($(category).is(":visible")) {
                isVisibleOnSaleSection = true;
            }
        });

        if ((isVisibleOnSaleSection || isVisibleFeaturedSection || isVisibleUserExclusiveSection) && isVisibleComingSoonSection) {
            comingSoonModule.show();
        } else {
            comingSoonModule.hide();
        }
    };

    updateExtra() {
        const countGroupsValidated = myApp.book.availabilityManager.findAllVisibleGroupsByField('visible', true).length;
        const countGroupsNoValidated = myApp.book.availabilityManager.findAllVisibleGroupsByField('visible', false).length;
        const countTotalGroups = countGroupsValidated + countGroupsNoValidated;
        const filterTitleContainer = $('[data-container="filter-title"]');
        const filterButtonContainer = $('[data-container="filter-button"]');

        if (this.checkAnyFiltersApplied()) {
            let filterTitleTextResult = filterTitleContainer.data("template-text");
            let filterButtonApply = filterButtonContainer.data("template-text");
            let filterButtonApplyOneResult = filterButtonContainer.data("template-text-one-result");

            if (filterButtonApply) {
                if (countGroupsValidated > 1) {
                    filterButtonApply = filterButtonApply.replace("%currentCount%", countGroupsValidated);
                    filterButtonContainer.html(filterButtonApply);
                } else {
                    filterButtonApplyOneResult = filterButtonApplyOneResult.replace("%currentCount%", countGroupsValidated);
                    filterButtonContainer.html(filterButtonApplyOneResult);
                }
            }
            if (filterTitleTextResult) {
                filterTitleTextResult = filterTitleTextResult.replace("%currentCount%", countGroupsValidated);
                filterTitleTextResult = filterTitleTextResult.replace("%totalCount%", countTotalGroups);
                filterTitleContainer.html(filterTitleTextResult);
                $('[data-container="default-filter-title"]').hide()
            }
        } else {
            $('[data-container="default-filter-title"]').show();
        }

        filterTitleContainer.hide();
        const resetFilters = document.querySelector("[data-container='reset-filters-lg']");

        if (this.checkAnyFiltersApplied()) {
            $('[data-entity="applied-filter"]').show();
            filterTitleContainer.show();
            resetFilters.style.display = '';
            $('[data-action="restore-filters"]').show();
            $("[data-hide-on-sort]").hide();
        } else {
            $('[data-entity="applied-filter"]').hide();
            resetFilters.style.display = 'none';
            let filterButtonNoResult = filterButtonContainer.data("template-text");
            if (filterButtonNoResult) {
                filterButtonNoResult = filterButtonNoResult.replace("%currentCount%", countTotalGroups);
                filterButtonContainer.html(filterButtonNoResult);
            }
            $("[data-hide-on-sort]").show();
        }
    };

    setVisibilityAvailableGroups(filterCategory) {
        $.each(myApp.book.availabilityManager.findAllVisibleGroups(), (i, group) => {
            let isValid = false;
            let hasCategories = true;

            if (!this.validateAllowPetsFilter(group.id) && this.filtersApplied.allowpets.length !== 0) {
                hasCategories = false;
            } else {
                $.each(this.filterAvailableOptions, (i, category) => {
                    if ((!this.validateCategoriesTypeFilter(group.id, category) && this.filtersApplied[category].length !== 0) && (category !== 'allowpets')) {
                        hasCategories = false;
                        if (this.filtersWithORCondition.indexOf(category) >= 0) {
                            if (filterCategory === category || this.filtersWithORCondition.indexOf(filterCategory) === -1) {
                                this.setTagCounterAmount(group, true, category);
                            } else {
                                if (this.filtersWithORCondition.indexOf(filterCategory) >= 0) {
                                    this.setTagCounterAmount(group, true, category);
                                }
                            }
                        }
                        return false;
                    }
                });
            }

            if (hasCategories) {
                group.visible = true;
                isValid = true;
                this.setTagCounterAmount(group, false, '');
            }

            if (isValid === false) {
                group.visible = false;
            }
        });
    };

    setVisibilityAvailableChollos(filterCategory) {
        if (filterCategory == "mealplan" || filterCategory == undefined) {
            $.each(myApp.book.availabilityManager.findAllVisibleGroups(), (i, group) => {
                group.chollos.forEach((chollo) => {
                    if (this.filtersApplied.mealplan.includes(chollo.mealPlanTag)) {
                        $("[data-entity='chollo'][data-id='" + chollo.id + "']").show();
                    } else {
                        $("[data-entity='chollo'][data-id='" + chollo.id + "']").hide();
                    }
                })
                if (this.filtersApplied.mealplan.length === 0) {
                    $("[data-entity='chollo']").show();
                }
            });
        }
    };

    validateCategoriesTypeFilter(groupId, categoryType) {
        let hasAllCategories = true;
        const categoriesFiltersSelected = this.filtersApplied[categoryType];
        const group = myApp.book.availabilityManager.findGroupByField('id', groupId);
        for (let i = 0; i < categoriesFiltersSelected.length; i++) {
            const hasTag = group.categories[categoryType].indexOf(categoriesFiltersSelected[i]);
            if (this.filtersWithORCondition.indexOf(categoryType) >= 0 && hasTag >= 0) {
                return true;
            }
            if (hasTag === -1) {
                hasAllCategories = false;
            }
        }
        return hasAllCategories;
    };

    validateAllowPetsFilter(groupId) {
        const group = myApp.book.availabilityManager.findGroupByField('id', groupId);
        if (this.filtersApplied.allowpets.length > 0) {
            return group.pets;
        }
    };

    checkAnyFiltersApplied(reload = false) {
        return (this.filtersApplied.thematic.length > 0 || this.filtersApplied.featureddates.length > 0 ||
            this.filtersApplied.allowpets.length > 0 || this.filtersApplied.mealplan.length > 0 ||
            this.filtersApplied.accommodationcategory.length > 0 || this.filtersApplied.rating.length > 0 ||
            this.filtersApplied.price.length > 0 || this.filtersApplied.freecancellation.length > 0 ||
            this.filtersApplied.location.length > 0 || this.filtersApplied.whentravel.length > 0 ||
            (reload && typeof isChollos !== 'undefined' && this.filtersContainer.length > 0));
    };

    printGroups() {
        let exclusiveGroupsContainer;

        $.each(myApp.book.availabilityManager.findAllVisibleGroups(), (i, group) => {
            const groupElement = $("[data-entity='group'][data-id='" + group.id + "']");
            const groupElementCategory = groupElement.closest(".group-list");
            const currentContainer = $("[data-container-list='groups']").find(groupElementCategory);
            groupElement.hide();

            if (!this.checkAnyFiltersApplied()) {
                myApp.book.availabilitySorter.appendBannerBetweenElements(i, currentContainer);
            }

            if (group.visible) {
                if (myApp.book.additionalMealPlansByChollo) {
                    this._filterByMealplan(groupElement, group);
                }
                groupElement.show();
                currentContainer.append(groupElement);
                if (!exclusiveGroupsContainer && currentContainer.attr('data-category') === 'user_exclusive') {
                    exclusiveGroupsContainer = currentContainer;
                }
            }

            if (!this.checkAnyFiltersApplied()) {
                if ($(currentContainer).attr('data-category') === 'on_sale') {
                    myApp.book.availabilitySorter.appendBannerBetweenElements(i, currentContainer);
                }
            }
        });

        if (exclusiveGroupsContainer) {
            const exclusiveTextClosure = exclusiveGroupsContainer.find('[data-target="exclusive-closure"]');
            exclusiveGroupsContainer.append(exclusiveTextClosure);
        }

        $.each($("[data-container-list='groups'] [data-category]"), (i, groupList) => {
            $(groupList).show();
            if (!$(groupList).find("[data-entity='group']").is(":visible")) {
                $(groupList).hide();
            }
        });

        const extraGroupsMessage = $('[data-not-sortable]');

        let numOfGroups = $("[data-container-list='groups'] [data-is-extra='0']:visible").length;
        let numOfExtraGroups = $("[data-container-list='groups'] [data-is-extra='1']:visible").length;
        let extraGroupsMessageText = document.querySelector("[data-entity='no-extra-chollo-title']");
        let extraGroupsMessageDescription = document.querySelector("[data-entity='no-extra-chollo-description']");

        if (extraGroupsMessage && extraGroupsMessageText && extraGroupsMessageDescription ) {
            if (numOfExtraGroups > 0) {
                extraGroupsMessage.show();
                if (numOfGroups > 0) {
                    extraGroupsMessageText.innerHTML = myApp.translator['search.page.recommendations.title'];
                    extraGroupsMessageDescription.innerHTML = myApp.translator['search.page.recommendations.description'];
                } else {
                    extraGroupsMessageText.innerHTML = myApp.translator['search.page.recommendations.no.filter.title'];
                    extraGroupsMessageDescription.innerHTML = myApp.translator['search.page.recommendations.no.filter.description'];
                }
            } else {
                extraGroupsMessage.hide();
            }
        }
    };

    restoreFilters() {
        $("[data-action='filter']").prop('checked', false);
        this.filtersContainer.find("[data-filter-type]").closest('.checkbox').removeClass('active');
        $('[data-container="filter-title"]').hide();
        $('[data-entity="applied-filter"]').hide();
        this.doFilter();
        myApp.scrollManager.scrollToElement('[data-container="groups"]');

        let getParams = new URLSearchParams(window.location.search);
        const allParams = getParams.keys();
        for (const key of Array.from(allParams)) {
            if (Object.keys(this.tagList).includes(key)) {
                getParams.delete(key);
            }
        }

        window.history.replaceState({}, '', getParams ? ('?' + getParams.toString()) : window.location.pathname);
    };

    restoreFilter(filterType, filterId) {
        $("[data-action='filter'][data-filter-type='" + filterType + "'][data-id='" + filterId + "']").prop('checked', false);
        this.filtersContainer.find("[data-filter-type='" + filterType + "'][data-id='" + filterId + "']").closest('.checkbox').removeClass('active');
        $('[data-container="filter-title"]').hide();
        $('[data-entity="applied-filter"]').hide();

        this.doFilter();

        let getParams = new URLSearchParams(window.location.search);
        let index = 0;
        for (const [key, value] of getParams.entries()) {
            if (key === filterType.toString() && value === filterId.toString()) {
                const paramToDelete = (index > 0 ? '&' : '') + filterType + '=' + filterId + ((index === 0 && getParams.size > 1) ? '&' : '');
                const newParams = getParams.toString().replace(paramToDelete, '');
                window.history.replaceState({}, '', newParams ? ('?' + newParams) : window.location.pathname);
                break;
            }
            index++;
        }
    };

    setSummaryFiltersApplied() {
        const template = $($('[data-filter-applied="template"]')[0]);
        const lists = $('[data-filter-applied="list"]');
        $.each(lists, (i, list) => {
            $(list).empty();
        });
        let item, name, id;
        let appliedFilters = [];

        $.each(this.filterAvailableOptions, (i, type) => {
            $.each($("[data-action='filter'][data-filter-type='" + type + "']"), (i, checkbox) => {
                id = type + '-' + $(checkbox).data('id');
                if ($(checkbox).is(":checked") && jQuery.inArray(id, appliedFilters) === -1) {
                    name = $('[data-filter-text="' + id + '"]').html();
                    $.each(lists, (i, list) => {
                        item = template.clone();
                        item.removeAttr('data-filter-applied');
                        item.attr('data-filter-applied-id', id);
                        item.find('[data-filter-name]').append(name);
                        item.show();
                        $(list).append(item);
                    });
                    appliedFilters.push(id);
                }
            });
        });
    };

    changeDomCounterAvailableGroups() {
        $.each(this.filtersContainer.find("[data-filter-type]"), (i, checkbox) => {
            checkbox = $(checkbox);
            const category = checkbox.data('filter-type');
            const filterCounterItem = checkbox.parent().next("[data-field='groups-counter']");
            if (!checkbox.is(":checked")) {
                filterCounterItem.removeClass("label-primary label-disabled").addClass("label-secondary-gray");
            }
            checkbox.removeClass("colored-grey").addClass("checked-primary");
            checkbox.prop('disabled', false);
            let hideCheckbox = checkbox.closest('[data-hide="checkbox"]');
            hideCheckbox.show();

            if (parseInt(filterCounterItem.text()) === 0 && !checkbox.is(":checked")) {
                hideCheckbox.hide();
            }

            const filterTypeContainer = $("[data-container-filter-type='" + category + "']");
            let separator =  filterTypeContainer.find('[data-element="separator"]');
            separator.hide();
            if (separator.next().is(":hidden")) {
                separator.show();
            }
        });
    };

    counterFiltersOnFilterCategory(category) {
        return (this.tagList[category].filter(categoryCheckbox => categoryCheckbox['amount'] > 0).length > 8);
    }

    calculateCollapseHeight() {
        const filtersCollapse = $('[name="filter-collapse"]');

        filtersCollapse.each((i, filterCollapse) => {
            let collapseHeight = 0;
            $(filterCollapse).children().each((item, filter) => {
                if (item < this.tagsDisplayedLimit && $(filter).css('display') != 'none') {
                    collapseHeight += $(filter).outerHeight(true);
                }
            });
            $(filterCollapse).css('min-height', Math.round(collapseHeight) + 'px');
        });
    }

    initializeWithGetParams() {
        $.each(this.filterAvailableOptions, (i, type) => {
            let filterParam = new URLSearchParams(window.location.search)
            for (const [key, value] of filterParam.entries()) {
                if (key == type) {
                    $('[data-filter-type="' + key + '"][data-id="' + value + '"]').click();
                }
            }
        });
    }

    refreshGetParams(currentElement) {
        let getParams = new URLSearchParams(window.location.search);
        const filterInfoContainer = currentElement.parent().find('[data-filter-type]');

        let appendFilter = true;
        let index = 0;
        for (const [key, value] of getParams.entries()) {
            if (key === filterInfoContainer.data('filter-type').toString() && value === filterInfoContainer.data('id').toString()) {
                appendFilter = false;
                if (filterInfoContainer.is(':not(:checked)')) {
                    const paramToDelete = (index > 0 ? '&' : '') + filterInfoContainer.data('filter-type') + '=' + filterInfoContainer.data('id') + ((index === 0 && getParams.size > 1) ? '&' : '');
                    const newParams = getParams.toString().replace(paramToDelete, '');
                    window.history.replaceState({}, '', newParams ? ('?' + newParams) : window.location.pathname);
                }
                break;
            }
            index++;
        }

        if (appendFilter) {
            getParams.append(filterInfoContainer.data('filter-type'), filterInfoContainer.data('id'));
            window.history.replaceState({}, '', '?' + getParams.toString());
        }
    }

    _filterByMealplan(element, group) {
        const filtersChecked = this.filtersApplied.mealplan;
        const mealPlans = myApp.book.additionalMealPlansByChollo[group.cholloId];
        const additionalMealPlansContainer = $(element).find('[data-entity="additional-meal-plans"]');
        const groupTitle = $(element).find('.group-title');
        const groupDescription = $(element).find('[data-entity="description"]');
        const groupPrice = $(element).find('.chollo-price');

        if (filtersChecked.length > 0) {
            for (let filter of filtersChecked) {
                if (!mealPlans) continue;
                const mealPlanApplied = mealPlans[filter];
                if (typeof mealPlanApplied === "undefined" || !mealPlanApplied) continue;
                groupTitle.html(mealPlanApplied.cholloMealPlanTitle);
                group.name = mealPlanApplied.cholloMealPlanTitle;
                groupDescription.html(mealPlanApplied.cholloMealPlanDescription);
                groupPrice.html(mealPlanApplied.cholloMealPlanPrice);
                group.price = mealPlanApplied.cholloMealPlanPrice;
                group.defaultMealPlan = mealPlanApplied.mealPlanId;

                if (additionalMealPlansContainer) {
                    const mealPlanAppliedBadges = $(additionalMealPlansContainer).find('[data-mealplan-id]');

                    $.each(mealPlanAppliedBadges, function(index, element) {
                        element.remove();
                    });

                    const mealPlanToPrint = [];
                    $.each(Object.values(mealPlans), function(index, mealPlan) {
                        if (mealPlan.mealPlanId === mealPlanApplied.mealPlanId) return;

                        mealPlanToPrint.push(mealPlan);
                    });

                    $.each(Object.values(mealPlanToPrint), function(index, mealPlan) {
                        const em = document.createElement('em');
                        em.setAttribute('data-mealplan-id', mealPlan.mealPlanId);
                        em.textContent = mealPlan.mealPlanText;
                        if (index < mealPlanToPrint.length - 1) {
                            em.textContent += ', ';
                        }
                        $(additionalMealPlansContainer).append(em);
                    });
                }
            }
        } else {
            if (!mealPlans) return;
            const mealPlanApplied = Object.values(mealPlans)[0];

            groupTitle.html(mealPlanApplied.cholloMealPlanTitle);
            groupDescription.html(mealPlanApplied.cholloMealPlanDescription);
            groupPrice.html(mealPlanApplied.cholloMealPlanPrice);
            group.price = mealPlanApplied.cholloMealPlanPrice;
            group.name = mealPlanApplied.cholloMealPlanTitle;
            group.defaultMealPlan = mealPlanApplied.mealPlanId;

            if (additionalMealPlansContainer) {
                const mealPlanAppliedBadges = $(additionalMealPlansContainer).find('[data-mealplan-id]');

                $.each(mealPlanAppliedBadges, function(index, element) {
                    element.remove();
                });
                
                const mealPlanToPrint = [];
                $.each(Object.values(mealPlans), function(index, mealPlan) {
                    if (mealPlan.mealPlanId === group.defaultMealPlan) return;

                    mealPlanToPrint.push(mealPlan);
                });

                $.each(Object.values(mealPlanToPrint), function(index, mealPlan) {
                    const em = document.createElement('em');
                    em.setAttribute('data-mealplan-id', mealPlan.mealPlanId);
                    em.textContent = mealPlan.mealPlanText;
                    if (index < mealPlanToPrint.length - 1) {
                        em.textContent += ', ';
                    }
                    $(additionalMealPlansContainer).append(em);
                });
            }

        }
    }
};
