import ItemSorter from "../itemSorterManager";
import SorterCriteria from "../../entity/sort/sorterCriteria";

export default class AvailabilitySorter {

    constructor() {
        this.count = 0;
        this.sortCriteria = null;
        this.getDestinations = true;
        this.emptyValues = [null, 100000000, 19700101010000];
        this.groupsList = [];
        this.extraGroupsList = [];
        this.initialize();
    }


    initialize() {
        myApp.book.availabilityManager.findAllVisibleGroups().forEach((group) => {
            if (group.isExtra) {
                this.extraGroupsList.push(group);
            } else {
                this.groupsList.push(group);
            }
        });

        this.eventDelegation();
        this.initializeWithGetParams();
    };

    eventDelegation() {
        $(".sorter_element").on("click", (e) => {
            const currentTarget = $(e.currentTarget);
            if (currentTarget.attr('data-field') === "distance") {
                if (!$('#user_location').hasClass('custom-modal-open')) {
                    $('[data-container="filters"]').removeClass('show');
                    $('body').removeClass('filters-opened');
                    myApp.customModalManager.openModal('#user_location');
                    return;
                } else {
                    myApp.customModalManager.closeModal('#user_location');
                }
            }
            this.sortCriteria = null;
            this.toggleClassActive(currentTarget);
            this.setSorterCriteria(currentTarget);
            $('#filter_overlay').show();
            setTimeout( () => {
                this.doSort();
                this.refreshGetParams(currentTarget);
            }, 200);
        });

        const sorterSelectedByDefault = document.getElementById('sorterSelectedByDefault');
        if (sorterSelectedByDefault) {
            const sorterSelectionValue = sorterSelectedByDefault.value;
            const sorterSelection = document.querySelector('[data-title="' + sorterSelectionValue + '"]');
            document.querySelector('.sorter_element.active').classList.remove('active');
            sorterSelection.classList.add('active');
        }
    };

    toggleClassActive(element) {
        $('.sorter_element').removeClass('active');
        let sortField = element.attr('data-field');
        let sortOrder = element.attr('data-order');
        document.querySelector('[data-element="sorter-selected"]').innerHTML = element.text().trim();
        $('.sorter_element[data-field="'+sortField+'"][data-order="'+sortOrder+'"]').addClass('active')
        myApp.customDropdownManagerNew.closeActiveDropdowns();
    };

    setSorterCriteria(criteria) {
        let sortField = criteria.attr('data-field');
        let sortOrder = criteria.attr('data-order');
        let sortTitle = criteria.attr('data-title');
        let sortType = criteria.attr('data-type');
        this.sortCriteria = new SorterCriteria(sortField, sortOrder, sortTitle, sortType);
    };

    doSort() {
        if (this.sortCriteria.field == 'distance') {
            if (this.getDestinations) {
                this.getDistance();
            }
            setTimeout(() => {
                this.sortGroups();
                this.sortChollos();
                this.printGroups();
            }, 800);
        } else {
            this.sortGroups();
            this.sortChollos();
            this.printGroups();
        }
    };

    getDistance() {
        this.itemSorter = new ItemSorter(myApp.book.availabilityManager.findAllVisibleGroups());

        $.each(this.itemSorter.sortItemsArray, (val, item) => {
            if (item.latitude && item.longitude) {
                this.getCholloDistance(item.id, item.latitude, item.longitude)
            }
        });
        this.getDestinations = false;
    };

    getCholloDistance(id, latitude, longitude) {
        let distance = myApp.locationManager.getDistanceBetweenUserAndDestination(new google.maps.LatLng(latitude, longitude));
        let container = "[data-entity='group']";
        let group = myApp.book.availabilityManager.findGroupByField('id', id);
        let textDistance = distance + ' km';
        let distanceElement = $(container + ' #distance_km_' + id + ' [data-km-distance]');

        group.distance = distance;

        $(container + '[data-id = "' + id + '"]').attr('data-distance', distance);
        $(container + ' #distance_km_' + id).removeClass('hidden');
        distanceElement.attr('data-km-distance', textDistance);
        distanceElement.text(textDistance.replace('.', ','));
    };

    sortGroups() {
        this.itemSorter = new ItemSorter(this.groupsList);
        this.itemExtraSorter = new ItemSorter(this.extraGroupsList);

        switch (this.sortCriteria.order) {
            case 'asc':
                if (this.sortCriteria.type === "object") {
                    this.itemSorter.sortByMultipleFields(this.sortCriteria.field, this.sortCriteria.order);
                    this.itemExtraSorter.sortByMultipleFields(this.sortCriteria.field, this.sortCriteria.order);
                } else {
                    this.itemExtraSorter.sortAsc(this.sortCriteria.field);
                    this.itemSorter.sortAsc(this.sortCriteria.field);
                    this.cleanAvailableList();
                }
                break;
            case 'desc':
                if (this.sortCriteria.type === "object") {
                    this.itemSorter.sortByMultipleFields(this.sortCriteria.field, this.sortCriteria.order);
                    this.itemExtraSorter.sortByMultipleFields(this.sortCriteria.field, this.sortCriteria.order);
                } else {
                    this.itemSorter.sortDsc(this.sortCriteria.field);
                    this.itemExtraSorter.sortDsc(this.sortCriteria.field);
                    this.cleanAvailableList();
                }
                break;
        }
    };

    sortChollos() {
        if ($('[data-container="chollos"]').length > 1) {
            let chollos = [];
            $.each(this.itemSorter.sortItemsArray, (i, group) => {
                chollos = new ItemSorter(group.chollos);
                switch (this.sortCriteria.order) {
                    case 'asc':
                        chollos.sortAsc(this.sortCriteria.field);
                        break;
                    case 'desc':
                        chollos.sortDsc(this.sortCriteria.field);
                        break;
                }
                $.each(chollos.sortItemsArray, (i, val) => {
                    $('[data-container="chollos"][data-chollos-group="' + group.id + '"]').append($('[data-entity="chollo"][data-id = "' + val.id + '"]'));
                });
            });
        }
    };

    cleanAvailableList() {
        let sortField = this.sortCriteria.field;
        let emptyFieldGroups = [];
        let availableGroups = [];
        $.each(this.itemSorter.sortItemsArray, (i, item) => {
            if (this.emptyValues.indexOf(item[sortField]) !== -1) {
                emptyFieldGroups.push(item);
            } else {
                availableGroups.push(item);
            }
        });
        this.itemSorter.sortItemsArray = availableGroups.concat(emptyFieldGroups);
    };

    printGroups() {
        let sorterTitle = "<div class='mb-4 mt-2 sorter-title'><span class='group-list-title text-weight-semibold text-s-24'>" + this.sortCriteria.title + "</span></div>";
        $('#filter_overlay').hide();
        if (this.itemSorter.sortItemsArray.length > 0) {
            $('.sorter-title').remove();
            $('[data-container="groups-sorted"]').append(sorterTitle);
            $.each(this.itemSorter.sortItemsArray, (i, val) => {
                this.appendBannerBetweenElements(i, '[data-container="groups-sorted"]');
                let groupContainer = $('[data-entity="group"][data-id = "' + val.id + '"]');
                $('[data-container="groups-sorted"]').append(groupContainer);
            });
            $('[data-category]').hide();
        }

        if (this.itemExtraSorter.sortItemsArray.length > 0) {
            $.each(this.itemExtraSorter.sortItemsArray, (i, val) => {
                let groupContainer = $('[data-entity="group"][data-id = "' + val.id + '"]');
                $('[data-container="groups-extra-sorted"]').append(groupContainer);
            });
        } else {
            this.hideElements()
        }
    };

    appendBannerBetweenElements(position, container) {
        $('[data-container="groups"]').find("[data-banner]").each( (i, banner) => {
            if ($(banner).data('position') == position) {
                $(container).append($(banner));
                $(banner).show();
                if (myApp.book.availabilityFilter.checkAnyFiltersApplied()) {
                    $(banner).hide();
                }
            }
        });
    };

    hideElements() {
        $('#menu_overlay').hide();
        $('[data-not-sortable]').hide();
        myApp.book.availabilityManager.reorderBanners();
    };

    initializeWithGetParams() {
        const urlParams = new URLSearchParams(window.location.search);
        const sorter = urlParams.get('sorter');
        const sorterOrder = urlParams.get('sorterOrder');
        if (sorter && sorterOrder) {
            let sorterDiv = [];
            if (myApp.deviceDetector.isSmScreen() || myApp.deviceDetector.isXsScreen()) {
                sorterDiv = document.querySelector('#containerOrderMenu .sorter_element[data-field="' + sorter + '"][data-order="' + sorterOrder + '"]');
            } else {
                sorterDiv = document.querySelector('#central_content .sorter_element[data-field="' + sorter + '"][data-order="' + sorterOrder + '"]')
            }
            if (sorterDiv) {
                sorterDiv.click();
            }
        }
    }

    refreshGetParams(currentTarget) {
        let getParams = new URLSearchParams(window.location.search);
        let appendSorter = true;
        for (const [key, value] of getParams.entries()) {
            if (key === 'sorter' && value === currentTarget.data('field').toString() && getParams.get('sorterOrder') === currentTarget.data('order').toString()) {
                appendSorter = false;
                break;
            }
        }

        if (appendSorter) {
            let newParams = getParams.toString();
            const newParamsStart = (newParams ? '&' : '');
            if (getParams.has('sorter')) {
                newParams = newParamsStart + newParams.replace('sorter=' + getParams.get('sorter'), 'sorter=' + currentTarget.data('field'));
                newParams = newParams.replace('sorterOrder=' + getParams.get('sorterOrder'), 'sorterOrder=' + currentTarget.data('order'));
            } else {
                newParams += newParamsStart + 'sorter=' + currentTarget.data('field') + '&sorterOrder=' + currentTarget.data('order');
            }
            window.history.replaceState(history.state, '', '?' + newParams);
        }
    }
};
