import Group from "../../entity/group";
import Chollo from "../../entity/chollo";
import DataLayerManager from "../trackers/dataLayer/dataLayerManager";
import ScrollManager from "@web/vpt-assets/assets/js/utilities/scrollManager";

export default class AvailabilityManager {

    constructor() {
        this.dataLayerManager = new DataLayerManager();
        this.scrollManager = new ScrollManager();
        this.availabilityList = [];
        this.categories = ['thematic', 'featureddates', 'mealplan', 'accommodationcategory', 'rating', 'price', 'freecancellation', 'location', 'whentravel'];
        this.initialize();
    }

    initialize() {
        this.initGroups();
        this.initGroupsAndChollos();
        let banners = document.querySelectorAll('[data-banner][data-seen][data-landing]');
        let groups = document.querySelectorAll('[data-container-list="groups"] [data-entity="group"][data-seen][data-landing]');
        this._initializeClickEvents(banners, groups);
        window.addEventListener("scroll", () => {
            this._initializeScrollEvents(banners, groups);
        });
    };

    _initializeScrollEvents(banners, groups) {
        banners.forEach((bannerElement) => {
            if (this.scrollManager.isElementPartiallyVisible(bannerElement) && !parseInt(bannerElement.dataset.seen)) {
                bannerElement.dataset.seen = 1;
                this._bannerPushData(bannerElement, 'banner_impression');
            } else if (!this.scrollManager.isElementPartiallyVisible(bannerElement)) {
                bannerElement.dataset.seen = 0;
            }
        });
        groups.forEach((element) => {
            if (this.scrollManager.isElementPartiallyVisible(element) && !parseInt(element.dataset.seen)) {
                element.dataset.seen = 1;
                this._offerPushData(element, 'offer_impression');
            } else if (!this.scrollManager.isElementPartiallyVisible(element)) {
                element.dataset.seen = 0;
            }
        });
    }

    _initializeClickEvents(banners, groups) {
        banners.forEach((bannerElement) => {
            bannerElement.addEventListener('click', () => {
                this._bannerPushData(bannerElement, 'banner_click');
            });
        });

        groups.forEach((element) => {
            element.addEventListener('click', () => {
                this._offerPushData(element, 'offer_click');
            });
        });
    }

    _bannerPushData(element, event) {
        let actualScreen = element.dataset.landing.replace(/^\/[^/]+/, '');
        this.dataLayerManager.pushData(JSON.stringify({
            'event': event,
            'screen': actualScreen.length > 0 ? actualScreen : 'home',
            'banner_id': element.dataset.banner
        }));
    }

    _offerPushData(element, event) {
        let actualScreen = element.dataset.landing.replace(/^\/[^/]+/, '');
        this.dataLayerManager.pushData(JSON.stringify({
            'event': event,
            'screen': actualScreen.length > 0 ? actualScreen : 'home',
            'offer': {
                'id': element.dataset.id,
                'name': element.dataset.name,
            }
        }));
    }

    initGroupsAndChollos() {
        $.each($('[data-entity="chollo"]'), (i, o) => {
            const value = $(o);
            const groupId = value.closest("[data-chollos-group]").data('chollos-group');
            const groupDomElement = $('[data-entity="group"][data-id="' + groupId + '"]');
            const group = new Group();

            group.id = groupDomElement.data('id');
            group.name = groupDomElement.data('name');
            group.route = groupDomElement.data('route');
            group.latitude = groupDomElement.data('latitude');
            group.longitude = groupDomElement.data('longitude');
            group.rate = null;
            if (typeof groupDomElement.data('rate') === 'number') {
                group.rate = groupDomElement.data('rate');
            }
            group.time = groupDomElement.data('time');
            group.price.desc = groupDomElement.data('price');
            group.price.asc = groupDomElement.data('price');
            group.type = groupDomElement.data('type');
            let favourite = groupDomElement.data('favourite');
            if (favourite) {
                group.favourite = favourite;
            }

            this.setNoVisibleGroups();
            this.setChollosToGroup(value, group.id);
            this.setMaxAndMinPriceGroup(group.id);
            if (!this.findGroup(group.id)) {
                this.availabilityList.push(group);
            }
        });
    };

    initGroups() {
        $.each($('[data-container="groups"] [data-entity="group"]'), (i, value) => {
            const group = new Group();
            const groupDomElement = $(value);
            group.id = groupDomElement.data('id');
            group.name = groupDomElement.data('name');
            group.route = groupDomElement.data('route');
            group.latitude = groupDomElement.data('latitude');
            group.longitude = groupDomElement.data('longitude');
            group.time = groupDomElement.data('time');
            group.price.desc = groupDomElement.data('price');
            group.price.asc = groupDomElement.data('price');
            group.type = groupDomElement.data('type');
            group.pets = groupDomElement.data('filter-allowpets');
            group.class = groupDomElement.data('chollo-class');
            group.isExtra = groupDomElement.data('is-extra');
            group.defaultMealPlan = groupDomElement.data('defaultMealPlan');
            group.cholloId = groupDomElement.data('cholloId');
            group.isTopGroup = groupDomElement.data('is-top-group');
            let favourite = groupDomElement.data('favourite');
            if (favourite) {
                group.favourite = favourite;
            }

            this.setTags(groupDomElement, group);
            if (groupDomElement.data('filter-allowpets') === 1) {
                group.categories['allowpets'][0] = 1;
            }

            group.rate = null;
            if (typeof groupDomElement.data('rate') === 'number') {
                group.rate = groupDomElement.data('rate');
            }
            this.availabilityList.push(group);
        });

        document.querySelectorAll('[data-entity="timer-container"]').forEach(item => {
            item.addEventListener('click', (event) => {
                let timerText = item.querySelector('[data-attribute="timer-text"]').textContent;
                let extraTimeElement = item.querySelector('[data-attribute="extra-time"]');

                if (timerText) {
                    let modalTitle = document.querySelector(item.getAttribute('custom-modal') + ' [data-entity="custom-modal-title"]');
                    let modalExtraTime = document.querySelector(item.getAttribute('custom-modal') + ' [data-entity="extra-time-text"]');
                    modalTitle.textContent = timerText;
                    if (extraTimeElement && modalExtraTime) {
                        modalExtraTime.classList.remove('hidden');
                    } else {
                        modalExtraTime.classList.add('hidden');
                    }
                }
            });
        });
    };

    findGroup(idGroup) {
        let found = false;
        $.each(this.availabilityList, (index, group) => {
            if (group.id === idGroup) {
                found = true;
                return false;
            }
        });
        return found;
    };

    findGroupByField(field, value) {
        return this.findAllGroupsByField(field, value)[0];
    };

    findAllVisibleGroups() {
        const groups = [];
        $.each(this.availabilityList, (index, group) => {
            groups.push(group);
        });
        return groups;
    };

    findAllGroupsByField(field, value) {
        const groupsByField = [];
        $.each(this.availabilityList, (index, group) => {
            if (group[field] === value) {
                groupsByField.push(group);
            }
        });
        return groupsByField;
    };

    findAllVisibleGroupsByField(field, value) {
        const groups = [];
        $.each(this.findAllVisibleGroups(), (index, group) => {
            if (group[field] == value) {
                groups.push(group);
            }
        });
        return groups;
    };

    setNoVisibleGroups() {
        $.each(this.availabilityList, (index, group) => {
            group.visible = false;
        });
    };

    setVisibleGroups() {
        $.each(this.availabilityList, (index, group) => {
            if (group.type !== Group.prototype.typeComingSoon) {
                group.visible = true;
            }
        });
    };

    setChollosToGroup(cholloDomElement, groupId) {
        const group = this.findGroupByField('id', groupId);
        const chollo = new Chollo();
        chollo.id = cholloDomElement.data('id');
        chollo.price = cholloDomElement.data('price');
        chollo.latitude = cholloDomElement.data('latitude');
        chollo.longitude = cholloDomElement.data('longitude');
        chollo.rate = cholloDomElement.data('rate');
        chollo.route = cholloDomElement.data('route');
        chollo.dateId = cholloDomElement.find('[data-date-id]').data('date-id');
        chollo.mealPlanTag = cholloDomElement.data('mealplan')
        group.chollos.push(chollo);
    };

    setMaxAndMinPriceGroup(groupId) {
        const prices = [];
        const group = this.findGroupByField('id', groupId);
        $.each(group.chollos, (index, chollo) => {
            prices.push(chollo.price);
        });
        group.price.asc = Math.min.apply(Math, prices);
        group.price.desc = Math.max.apply(Math, prices);
    };

    setTags(groupDomElement, group) {
        $.each(this.categories, (index, category) => {
            const tags = groupDomElement.data('filter-' + category);
            this.setTag(category, group, tags);
        });
    };

    setTag(category, group, tagsIds) {
        let tags = [];
        if (typeof tagsIds == 'number') {
            tags.push(tagsIds);
        } else if (tagsIds && tagsIds != '' && tagsIds.indexOf(";") != -1) {
            tags = tagsIds.split(";");
        }
        if (category == 'accommodationcategory') {
            group.categories[category].push(parseInt(tags));
        } else {
            $.each(tags, (index, tag) => {
                group.categories[category].push(parseInt(tag));
            });
        }
    };

    reorderBanners() {
        $('[data-banner]').hide();
        document.querySelectorAll('[data-banner]').forEach((banner) => {
            const parent = $(banner).parent();
            const childCount = parent.find('> :visible').length;
            const bannerIndex = parseInt(banner.getAttribute('data-position'));
            if (bannerIndex < childCount - 1) {
                $(banner).insertAfter(parent.find('> :visible').eq(bannerIndex))
                $(banner).show();
            }
        })
    }
};

