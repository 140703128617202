export default class UserLocationManager
{
    constructor()
    {
        this.paramDistance = myApp.urlManager.getUrlParam('distance');
        this.searchBox = null;
        this.map = null;
        this.marker = [];
        this.initialize();
        this.eventDelegation();
    }

    initialize()
    {
        if (this.paramDistance && $('#user_location').length > 0) {
            if (window.parent.location.href.match(/&distance=/)) {
                if (typeof (history.pushState) != "undefined") {
                    var obj = {Title: document.title, Url: window.parent.location.pathname};
                    history.pushState(obj, obj.Title, obj.Url);
                } else {
                    window.parent.location = window.parent.location.pathname;
                }
            }
        }
        if (myApp.browserStorageService.getItem('realLocation')) {
            myApp.browserStorageService.setItem('realLocation', true);
            this.__setDropdownDistanceView();
        }
    }

    eventDelegation()
    {
        $('body').on('click', '[data-action="set-user-location"]', () =>
        {
            this.setUserLocation();
        });

        let modalId = '#user_location';
        let modal = document.querySelector(modalId);
        if (modal) {
            modal.addEventListener('custom-modal-shown', () => {
                this.showModal()
            });
        }
    }

    showModal()
    {
        $('#menu_overlay').fadeOut();

        if (this.map == null || this.searchBox == null) {
            this.initializeUserLocationMap();
            // this.initializeSearchBox();
        }
    }

    async initializeUserLocationMap() {
        const {Map} = await google.maps.importLibrary("maps");
        const {AdvancedMarkerElement} = await google.maps.importLibrary("marker");
        const latitudeStorage = myApp.browserStorageService.getItem('latitude');
        const longitudeStorage = myApp.browserStorageService.getItem('longitude');

        let latitude = 40.415363;
        let longitude = -3.707398;
        let isStatic = true;

        if (latitudeStorage !== null && longitudeStorage !== null) {
            latitude = latitudeStorage;
            longitude = longitudeStorage;
            isStatic = false;
        }

        this.setBrowserStorageLatLng(latitude, longitude);

        this.map = new Map(document.getElementById('map-location'), {
            center: {lat: parseFloat(latitude), lng: parseFloat(longitude)},
            zoom: isStatic ? 4 : 13,
            mapId: 'map-location',
            libraries: ["geometry", "marker"],
            restriction: {
                latLngBounds: {north: 85, south: -85, west: -180, east: 180},
                strictBounds: true
            }
        });

        this.addAdvancedMarker({lat: parseFloat(latitude), lng: parseFloat(longitude)}, AdvancedMarkerElement);

        this.map.addListener("click", (e) => {
            this.clearMarkers();
            this.addAdvancedMarker(e.latLng, AdvancedMarkerElement);
            this.setBrowserStorageLatLng(e.latLng.lat(), e.latLng.lng());
        });
    }

    addAdvancedMarker(latlng, AdvancedMarkerElement) {
        let url = myApp.config.cdnPath.img + '/maps/marker-no-selected.svg';
        const marker = document.createElement("div");
        marker.classList.add('filter-marker');

        const markerElement = new AdvancedMarkerElement({
            position:latlng,
            map: this.map,
            content: marker
        });
        this.marker.push(markerElement);
    }

    clearMarkers() {
        this.marker.forEach(marker => {
            marker.map = null;
        });
    }

    initializeSearchBox()
    {
        const input = document.getElementById("location-input");
        this.searchBox = new google.maps.places.SearchBox(input);

        this.searchBox.addListener("places_changed", () => {
            const places = this.searchBox.getPlaces();
            if (places.length !== 1) {
                input.classList.add("border-error");
                return;
            }
            input.classList.remove("border-error");

            let bounds = new google.maps.LatLngBounds();
            let currentPlace = places[0];
            if (currentPlace.geometry.viewport) {
                bounds.union(currentPlace.geometry.viewport);
            } else {
                bounds.extend(currentPlace.geometry.location);
            }
            this.marker.setPosition(currentPlace.geometry.location);
            this.map.fitBounds(bounds);

            this.setBrowserStorageLatLng(currentPlace.geometry.location.lat(), currentPlace.geometry.location.lng());
        });
    }

    setBrowserStorageLatLng(latitude, longitude)
    {
        myApp.browserStorageService.setItem('latitude', latitude);
        myApp.browserStorageService.setItem('longitude', longitude);
    }

    setUserLocation()
    {
        $('#menu_overlay').fadeOut();

        myApp.browserStorageService.setItem('realLocation', true);
        this.__setDropdownDistanceView();

        myApp.browserHistoryManager.removeState('#user_location');
        if (this.paramDistance && $('#user_location').length > 0) {
            if ($('#containerOrderMenu').is(":visible")) {
                $('#menu_overlay').fadeIn();
            }
        }
        myApp.book.availabilitySorter.getDestinations = true;

        if (myApp.deviceDetector.isSmScreen() || myApp.deviceDetector.isXsScreen()) {
            $('#containerOrderMenu .sorter_element[data-order="asc"][data-field="distance"]').click();
        } else {
            $('#central_content .sorter_element[data-order="asc"][data-field="distance"]').click();
        }
    };

    __setDropdownDistanceView()
    {
        const dropdownDistanceElement = $('.dropdownDistance');
        dropdownDistanceElement.addClass('dropdown-toggle');
        dropdownDistanceElement.data('toggle', 'dropdown');
        dropdownDistanceElement.attr('data-toggle', 'dropdown');
        dropdownDistanceElement.removeAttr('data-target');
    }
}