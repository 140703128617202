import SliderManager from "./slider/sliderManager";
import DataLayerManager from "./trackers/dataLayer/dataLayerManager";

export default class SuggestedChollosSliderManager {

    constructor() {
        this.sliderManager = new SliderManager();
        this.dataLayerManager = new DataLayerManager();
        this.eventDelegation();
    }

    eventDelegation() {
        let slider = document.querySelector('[data-entity="slider"]');
        let sliders = document.querySelectorAll('[data-entity="highlighted-tag"]');

        window.addEventListener('load', () => {
            let swiperElement = this.registerSlider(slider);
            this.checkSliders(sliders, true);

            swiperElement?.on('slideChange', () => {
                this.checkSliders(sliders);
            });

        });
    }

    checkSliders(sliders, initialize = false) {
        sliders.forEach((element, index) => {
            if (initialize) {
                element.addEventListener('click', () => {
                    this.highlightedTagPushData('click_highlighted_tag', element, index);
                });
            }

            if (element.classList.contains('swiper-slide-visible') && !parseInt(element.dataset.seen)) {
                element.dataset.seen = 1;
                this.highlightedTagPushData('visible_highlighted_tag', element, index);
            } else if (!element.classList.contains('swiper-slide-visible')) {
                element.dataset.seen = 0;
            }
        });
    }

    registerSlider(slider) {
        if (!slider) return;
        slider.querySelector('.swiper-wrapper').classList.remove('visibility-hidden');
        return this.sliderManager.addSlider('[data-entity="slider"]', null, null, 1, true);
    }

    highlightedTagPushData(event, element, position) {
        this.dataLayerManager.pushData(JSON.stringify({
            event: event,
            tag_id: element.dataset.highlightedTagsIds.match(/\d+/)[0],
            tag_name: element.dataset.highlightedTag,
            tag_position: position
        }));
    }
}