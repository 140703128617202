export default class Group{
    constructor() {
        this.id = null;
        this.route = 0;
        this.latitude = null;
        this.longitude = null;
        this.distance = 100000000;
        this.visible = true;
        this.rate = null;
        this.time = null;
        this.price = {desc: null, asc: null};
        this.type = null;
        this.chollos = [];
        this.pets = false;
        this.class = null;
        this.name = null;
        this.hotelName = null;
        this.stars = 0;
        this.keys = 0;
        this.cancelationLabel = null;
        this.isExtra = false;
        this.defaultMealPlan = null;
        this.cholloId = null;
        this.categories = {
            'thematic': [],
            'featureddates': [],
            'allowpets': [],
            'mealplan': [],
            'accommodationcategory': [],
            'rating': [],
            'price': [],
            'freecancellation': [],
            'location': [],
            'whentravel': []
        };
        this.isTopGroup = false;
        this.favourite = null;
    }

    hasCategories() {
        return (
            this.hasCategory('thematic') ||
            this.hasCategory('featureddates') ||
            this.hasCategory('allowpets') ||
            this.hasCategory('mealplan')||
            this.hasCategory('accommodationcategory') ||
            this.hasCategory('rating') ||
            this.hasCategory('price') ||
            this.hasCategory('freecancellation') ||
            this.hasCategory('location') ||
            this.hasCategory('whentravel'));
    }

    hasCategory(category) {
        return (this.categories[category].length > 0)
    }

    hasTag(category, idSearchTag) {

        let exists = false;

        $.each(this.categories[category], (i, idTag) => {
            if (idTag == idSearchTag) {
                exists = true;
            }
        });

        return exists;
    }
};

Group.prototype.typeExclusive = 'exclusive';
Group.prototype.typeComingSoon = 'coming_soon';


